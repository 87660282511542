@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
	font-family: 'Recline';
	src: url('/assets/Fonts/regular-recline-458134/Recline-Regular.ttf');
	/* src: url('/assets/Fonts/recline-bold/Recline-Bold.ttf'); */
	/* src: url('/assets/Fonts/medium-recline-458136/Recline-Medium.ttf'); */
	/* src: url('/assets/Fonts/semi-bold-recline-458138/Recline-SemiBold.ttf'); */
	/* src:url('../public/assets/Fonts/italic-recline-458135/Recline-Italic.ttf');
  src:url('../public/assets/Fonts/semi-bold-italic-recline-458139/Recline-SemiBoldItalic.ttf');
  font-style: normal; */
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

/* please dont put html tag here as it is causing the pages to not scroll to top */
body {
	max-width: 100vw;
	font-family: 'Work Sans';
}

#__next {
	height: 100%;
}

a {
	color: inherit;
	text-decoration: none;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.right-arrow-icon:hover {
	stroke: #069baa;
}

.hoverable {
	cursor: pointer;
	filter: drop-shadow(0px 4px 8px rgba(34, 34, 34, 0.2));
}

.hoverable:hover {
	filter: drop-shadow(0px 4px 8px rgba(64, 182, 194, 0.3));
}

#header-location-pin:hover > div > p {
	color: #069baa;
}

.popover_title {
	position: relative;
	text-decoration: none;
	color: rgb(228, 68, 68);
	text-align: center;
}

.popover_wrapper {
	position: relative;
	display: inline-block;
}
.popover_content {
	padding: 20px;
	visibility: hidden;
	position: absolute;
	transform: translate(0, 10px);
	background-color: #bfbfbf;
	width: auto;
	background: none;
}
.popover_content:before {
	position: absolute;
	z-index: -1;
	content: '';
	right: calc(50% - 10px);
	top: -8px;
	border-color: transparent transparent #bfbfbf transparent;
	transition-duration: 0.3s;
	transition-property: transform;
}

.popover_wrapper:hover .popover_content {
	z-index: 10;
	opacity: 1;
	visibility: visible;
	transform: translate(0, 20px);
	transition: all 0.2s cubic-bezier(0.75, -0.02, 0.2, 0.97);
	top: 0px;
	overflow: hidden;
}

.popover_right {
	right: 0px;
}

.popover__message {
	text-align: center;
}

.minty-on-hover:hover {
	color: #069baa;
}

.minty1-on-hover:hover {
	color: #047992;
}

.move-up-transition:hover {
	margin-bottom: 3px;
	transition: all 0.2s;
}

.shake-cart-icon {
	animation-name: shake-cart-on-update;
	animation-duration: 0.2s;
	animation-iteration-count: 3;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.circular-loader-unlmtd {
	border: 2px solid #f3f3f3; /* Light grey */
	border-top: 2px solid #c072ac; /* Blue */
	border-radius: 50%;
	width: 26px;
	height: 26px;
	animation: spin 1s linear infinite;
}

@keyframes shake-cart-on-update {
	0% {
		margin-bottom: 0px;
	}
	50% {
		margin-bottom: 4px;
	}
	100% {
		margin-bottom: 0px;
	}
}

.roundicon-bubble {
	display: none !important;
}

.CART_PAGE {
	& #offers-list-entry-card {
		background: #fff5b7;
		border-radius: 16px;
	}

	& .FURLENCO_CARE_PROGRAM {
		background: linear-gradient(0deg, #b4e1e5 0%, #ceedf0 100%);
	}

	& .FLEXI_CANCELLATION {
		background: linear-gradient(180deg, #feead2 0%, #fdcfa6 100%);
	}
}

.CHECKOUT_SUMMARY_PAGE {
	& #offers-list-entry-card {
		background-color: white;
		border: 1px solid #bcbcbc;
		border-radius: 16px;
	}

	& #VAS_ENTRY_CARD_STARS {
		display: none;
	}

	& .FURLENCO_CARE_PROGRAM {
		background-color: white;
		border-radius: 16px;
		border: 1px solid #bcbcbc;
	}

	& .FLEXI_CANCELLATION {
		background-color: white;
		border-radius: 16px;
		border: 1px solid #bcbcbc;
	}
}

.unlmtd{
	background-color: #222038;
}

